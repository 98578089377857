$(document).ready(function() {
	$(".owl-carousel").owlCarousel({
		nav : true, // Show next and prev buttons
		pagination: false,
		autoplay: 9000,
		singleItem:true,
		rewindSpeed: 10000,
		loop:true,
		items:1,
		itemsScaleUp: true,
		navText: ['<span class="fa fa-angle-left"></span>','<span class="fa fa-angle-right"></span>'],
		afterUpdate: function() { handleSliderHeights(); },
		slideSpeed: 3000,
		stopOnHover : true,
	});

	// Hintergrundbilder austauschen
	$(".owl-carousel").each(function() {
		var items = $(".item",this);
		$(items).each(function() {
			var item = $(this);
			if (typeof $(item).attr("data-image") !== "undefined") {
				var picURL = $(item).attr("data-image");
				$.ajax(picURL).done(function() {
					$(item).css("background-image","url('"+picURL+"')");
				});
			}
		});
	});


	// Die Höhe der Slide anpassen, wenn der Content länger als 400px lang ist
	handleSliderHeights();
	function handleSliderHeights() {
		$(".owl-carousel").each(function() {
			var slider = $(this);
			$(".item",slider).each(function() {
				var slide = $(this);
				$(slide).css("height", null);
				var targetHeight = $(slide)[0].scrollHeight;
				if ($(slide)[0].scrollHeight < 400) { targetHeight == 400; }
				$(slide).css("height",targetHeight+"px");
			});
		});
	}
});
